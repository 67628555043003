import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/Layout"
import SEO from "../../components/seo"

import * as S from "../../components/Pages/general"

const ComboUltimatePage = () => {
  const { comboUltimateImg } = useStaticQuery(graphql`
    query {
      comboUltimateImg: file(relativePath: { eq: "combo-ultimate-x.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title="Combo Ultimate - 6 cursos 40% OFF"
        description="Nesse combo incrível você vai adquirir 6 cursos voltados a desenhar e aprimorar seu amor pela arte e por mangas, aprenderá a criar seus próprios personagens e colorir seus desenhos para ter ainda mais vida."
        image="/img/combo-ultimate-x.png"
      />
      <S.GeneralWrapper>
        <S.GeneralTitle>Combo Ultimate - 6 Cursos</S.GeneralTitle>
        <S.GeneralHighlight>
          Se você que obter o máximo de conhecimento para aprimorar ainda mais
          suas técnicas de desenho aqui está a sua melhor opção.
        </S.GeneralHighlight>
        <S.GeneralSection>
          <S.GeneralBox shadow>
            <S.GeneralExternalLink
              href="http://bit.ly/aprendendo-desenhar-combo-ultimate"
              target="_blank"
            >
              <Img
                fluid={comboUltimateImg.childImageSharp.fluid}
                alt="Imagem com o título de combo ultimate e vários personagens de anime ao funto"
              />
            </S.GeneralExternalLink>
          </S.GeneralBox>
          <S.GeneralBox shadow>
            <S.GeneralSubTitle>Combo Ultimate (40% OFF)</S.GeneralSubTitle>
            <S.GeneralStrongText>
              Isso é o que você vai receber ao garantir o Combo Ultimate
            </S.GeneralStrongText>
            <S.GeneralList>
              <S.GeneralListItem>- Colorindo Fan Art</S.GeneralListItem>
              <S.GeneralListItem>
                - Como Criar Seus Próprios Personagens
              </S.GeneralListItem>
              <S.GeneralListItem>
                - Curso de Elementos Naturais em Cenários
              </S.GeneralListItem>
              <S.GeneralListItem>
                - Curso de Composição de Desenho
              </S.GeneralListItem>
              <S.GeneralListItem>
                - Curso de Técnicas de Perspectiva em Cenários
              </S.GeneralListItem>
              <S.GeneralListItem>
                - Idade e Etnias de Personagens
              </S.GeneralListItem>
            </S.GeneralList>
          </S.GeneralBox>
        </S.GeneralSection>
        <S.GeneralFinalCall
          href="http://bit.ly/aprendendo-desenhar-combo-ultimate"
          target="_blank"
        >
          OBTENHA TODOS OS DETALHES GARANTA SEU DESCONTO DE 40 POR CENTO
        </S.GeneralFinalCall>
      </S.GeneralWrapper>
    </Layout>
  )
}

export default ComboUltimatePage
